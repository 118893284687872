import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { t } from 'i18next'

import { Languages } from '../resources/locales/Languages'
import i18n from '../resources/locales/i18n'
import { useAuth } from '../2_templates/AuthProvider'

export const Header = ({ children }: any) => {
  const { isAuthenticated } = useAuth()
  const currentDomain = window.location.hostname

  function getTitleString() {
    if (isAuthenticated && t<string>('seo_premium.title'))
      return t<string>('seo_premium.title')
    return t<string>('seo.title')
  }

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{getTitleString()}</title>
        <meta name="description" content={t<string>('seo.description')} />
        <meta property="og:title" content={t<string>('seo.og:title')} />
        <meta
          property="og:description"
          content={t<string>('seo.og:description')}
        />
        <meta property="og:site_name" content={t<string>('seo.og:site_name')} />
        <meta
          property="og:site_name"
          content={t<string>('seo.twitter:description')}
        />
        <meta property="og:url" content={`https://${currentDomain}`} />
        {Languages.LANGUAGES.filter(
          (lang) => lang.code !== i18n.resolvedLanguage
        ).map((lang, index) => (
          <link
            rel="alternate"
            hrefLang={lang.code}
            href={`https://${currentDomain}/${lang.code}`}
            key={index}
          />
        ))}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: t<string>('seo.og:site_name'),
            url: `https://${currentDomain}`,
            description: t<string>('seo.description'),
            creator: {
              '@type': 'Organization',
              name: 'RiggedWheel',
            },
            sameAs: [
              'https://www.facebook.com/riggedwheel',
              'https://www.twitter.com/riggedwheel',
              'https://www.instagram.com/riggedwheel',
            ],
          })}
        </script>
      </Helmet>
      {children}
    </HelmetProvider>
  )
}
