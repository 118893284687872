import { Link, useLocation } from 'react-router-dom'
import { t } from 'i18next'
import LanguageDropdown from '../4_molecules/LanguageDropDown'
import React, { useState } from 'react'
import { useAuth } from './AuthProvider'

export const NavBar = () => {
  const { isAuthenticated } = useAuth()

  const selected =
    'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium no-underline'
  const unselected =
    'text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline'

  const isPricing = useLocation().pathname === '/pricing'

  return (
    <nav
      className={`${!isAuthenticated ? 'bg-gradient-to-r from-yellow-400 to-red-500' : 'bg-gray-100'}`}
    >
      <div className="mx-auto hidden max-w-7xl px-2 sm:flex sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between px-1">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="shrink-0 items-center">
              <Link to="/">
                <h4 className="text text-black no-underline">Wheel Decider</h4>
              </Link>
            </div>
            <div className="block sm:ml-6">
              <div className="flex space-x-4">
                <Link to="/" className={!isPricing ? selected : unselected}>
                  {t<string>('nav.home')}
                </Link>
                <Link
                  to={`/pricing`}
                  className={isPricing ? selected : unselected}
                >
                  {t<string>('nav.pricing')}
                </Link>
              </div>
            </div>
          </div>
          <LanguageDropdown />
        </div>
      </div>

      <div className="flex w-full items-center justify-between sm:hidden">
        <div className="flex grow flex-row items-center space-y-1 px-2 pb-3 pt-2">
          <Link
            to="/"
            className={`${!isPricing ? selected : unselected} block`}
          >
            {t<string>('nav.home')}
          </Link>
          <Link
            to={`/pricing`}
            className={`${isPricing ? selected : unselected} block`}
          >
            {t<string>('nav.pricing')}
          </Link>
        </div>
        <div className="flex grow justify-end">
          <LanguageDropdown />
        </div>
      </div>
    </nav>
  )
}
