import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import { files } from '.'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: { translation: files.ar },
      bn: { translation: files.bn },
      de: { translation: files.de },
      en: { translation: files.en },
      es: { translation: files.es },
      fr: { translation: files.fr },
      hi: { translation: files.hi },
      id: { translation: files.id },
      ja: { translation: files.ja },
      ko: { translation: files.ko },
      nl: { translation: files.nl },
      pt: { translation: files.pt },
      ru: { translation: files.ru },
      zh: { translation: files.zh },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
