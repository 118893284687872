import axios from 'axios'
import { t } from 'i18next'
import { WheelData } from 'react-custom-roulette/dist/components/Wheel/types'
import toast from 'react-hot-toast'

export const OPTION_COLORS = [
  '#DC143C', // Crimson
  '#F28500', // Tangerine
  '#FFBF00', // Amber
  '#32CD32', // Lime Green
  '#50C878', // Emerald
  '#40E0D0', // Turquoise
  '#4169E1', // Royal Blue
  '#191970', // Midnight Blue
  '#9966CC', // Amethyst
  '#FF00FF', // Magenta
  '#FFD700', // Gold
  '#FF69B4', // Hot Pink
  '#00CED1', // Dark Turquoise
  '#8A2BE2', // Blue Violet
  '#228B22', // Forest Green
  '#FF4500', // Orange Red
  '#1E90FF', // Dodger Blue
  '#8B4513', // Saddle Brown
  '#000000', // Black
]

export const empty_spin_data = (): Person => {
  return {
    place: 'middle',
    picked: false,
    option: '',
    style: nextStyle(),
    p: undefined,
    index: -1,
  }
}

export const sample_data: Person[] = [
  {
    place: 'middle',
    picked: false,
    option: 'John',
    style: nextStyle(),
    p: undefined,
    index: -1,
  },
  {
    place: 'middle',
    picked: false,
    option: 'Maria',
    style: nextStyle(),
    p: undefined,
    index: -1,
  },
  {
    place: 'middle',
    picked: false,
    option: 'Michael',
    style: nextStyle(),
    p: undefined,
    index: -1,
  },
]

export function nextStyle(): { background_color: string; text_color: string } {
  if (!Array.isArray(OPTION_COLORS)) {
    throw new Error('Colors array is not defined')
  }

  const nextColorIndex = localStorage.getItem('nextColorIndex')
  let colorIndex: number | null
  if (!nextColorIndex) {
    colorIndex = 0
    localStorage.setItem('nextColorIndex', '3') // 3 because the empty wheel has 3 names on it.
  } else {
    colorIndex = isNaN(parseInt(nextColorIndex)) ? 0 : parseInt(nextColorIndex)
    localStorage.setItem(
      'nextColorIndex',
      String((colorIndex + 1) % OPTION_COLORS.length)
    )
  }
  const backgroundColor = OPTION_COLORS[colorIndex % OPTION_COLORS.length]

  const textColor = contrastingColor(backgroundColor)

  return { background_color: backgroundColor, text_color: textColor }
}

export const people_to_wheel_data = (people: Person[] = []): WheelData[] => {
  if (!people) throw new Error('People is undefined')
  const people_filtered = people
    .filter((el) => el !== undefined)
    .map((person) => {
      const wheel_data: WheelData = {
        option: person.option ?? '',
        style: {
          backgroundColor: person.style.background_color,
          textColor: person.style.text_color,
        },
      }
      return wheel_data
    })
  if (!people_filtered?.length) return []
  return people_filtered
}

export const calculateAndFillSpinProbabilities = async (
  people: Person[]
): Promise<Person[]> => {
  if (!people) throw new Error('People is undefined')
  const peopleNew: Person[] = people

  const { total_probability, amtWithoutProbability } = peopleNew?.reduce(
    (acc, spin) => {
      if (!spin.p) {
        acc.amtWithoutProbability++
      } else {
        acc.total_probability += Math.floor(spin.p)
      }
      return acc
    },
    { total_probability: 0, amtWithoutProbability: 0 }
  )

  if (total_probability > 100) {
    throw new Error(t<string>('spinner.error.p100'))
  }

  // Calculate the value to be divided among the elements without probabilities
  const toBeDivided = (100 - total_probability) / amtWithoutProbability

  // Use map to set the probability for each element without a probability
  peopleNew.map((person) => {
    person.p = Math.floor(person.p ?? toBeDivided)
    return person
  })

  return Promise.resolve(peopleNew)
}

export const pickNextPerson = async (
  keep_names_after_spin: boolean,
  people: Person[]
): Promise<Person> => {
  if (!people) throw new Error('People is undefined, pickNextPerson')
  const unpicked = people.filter(
    (x) => x !== undefined && (!x.picked || keep_names_after_spin)
  )
  unpicked.sort(sortPeople)

  //pick a random person with the same type
  const type = unpicked[0].place
  const filtered = unpicked.filter((x) => x.place === type)

  const person = filtered[Math.floor(Math.random() * filtered.length)]
  person.picked = true

  return Promise.resolve(person)
}

/**
 * Picks a person from a list, based on the probability that person has.
 * @param people
 * @param keep_names_after_spin
 */
export const pickNextPersonProb = async (
  people: Person[],
  keep_names_after_spin: boolean
): Promise<Person> => {
  if (!people) throw new Error('People is undefined, pickNextPersonProb')
  const options_to_pick = []
  // repeat the index p times in the array.
  // the array will have exactly 100 indexes.
  for (let i = 0; i < people.length; i++) {
    const person = people[i]
    if (!person) continue
    if (person.picked && !keep_names_after_spin) continue
    const p: number = person.p || 1
    for (let j = 0; j < p; j++) {
      options_to_pick.push(i)
    }
  }

  const index_to_pick =
    options_to_pick[Math.floor(Math.random() * options_to_pick.length)]
  const person = people[index_to_pick]
  person.picked = true
  return person
}

export const pickOrderedList = (people: Person[]): Person => {
  if (!people) throw new Error('People is undefined')
  //Filter out all picked people
  const unpicked = people.filter((x) => x !== undefined && !x.picked)
  console.log('unpicked:', unpicked)

  //The first person in the list is the next person to be picked, as the list is in order
  const picked = unpicked[0]
  picked.picked = true

  if (!picked) throw new Error('No unpicked person found')

  return picked
}

export const submitEmail = async (firstName: string, email: string) => {
  try {
    const response = await axios.post('/marketing/add', {
      name: firstName,
      email,
      campaign_name: 'AppRelease',
    })

    if (!response.data.error) {
      console.log('Form submitted successfully:', response.data)
      toast.success('We will notify you when the app is released!')
      return Promise.resolve()
    } else {
      console.log('Unexpected response content:', response.data)
      toast.error(
        'An unexpected error occurred. We could not submit your email address'
      )
    }
  } catch (e) {
    console.error('Error submitting form:', e)
    toast.error(
      'An unexpected error occurred. We could not submit your email address'
    )
  }

  return Promise.reject()
}

const sortPeople = (a: Person, b: Person) => {
  if (a.place === 'first') return -1
  if (b.place === 'first') return 1
  if (a.place === 'last') return 1
  if (b.place === 'last') return -1
  return 0
}

export interface Person {
  place: 'first' | 'middle' | 'last'
  picked: boolean
  option: string
  style: { background_color: string; text_color: string }
  p?: number
  index: number
}

export function contrastingColor(
  hex: string,
  factorAlpha: boolean = false
): string {
  if (!hex) return '#000'
  const match = hex
    .replace(
      /^#?(?:(?:(..)(..)(..)(..)?)|(?:(.)(.)(.)(.)?))$/,
      '$1$5$5$2$6$6$3$7$7$4$8$8'
    )
    .match(/(..)/g)
  if (!match) return '#000'
  const [r, g, b, a] = match.map((rgb) => parseInt(rgb, 16))
  return (r * 299 + g * 587 + b * 114) / 1000 >= 128 ||
    (a !== undefined && factorAlpha && a < 128)
    ? '#000'
    : '#FFF'
}
