import React, { useEffect, useState } from 'react'
import { Languages } from '../resources/locales/Languages'
import { useNavigate, useParams } from 'react-router-dom'
import i18n from 'i18next'

const LANGUAGES = Languages.LANGUAGES
type Language = Languages.Language

function LanguageDropdown() {
  const navigate = useNavigate()
  const { lang_or_token } = useParams()
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    LANGUAGES[3]
  )

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedCode = event.target.value
    changeLanguage(selectedCode, true)
  }

  function changeLanguage(selectedCode: string, navigationOn: boolean) {
    const selectedLanguage = LANGUAGES.find(
      (language) => language.code === selectedCode
    )
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage.code)
      setSelectedLanguage(selectedLanguage)
      if (navigationOn) navigate(`/${selectedLanguage.code}`)
    }
  }

  useEffect(() => {
    //if language is included in the list, then redirect
    if (LANGUAGES.some((language) => language.code === lang_or_token)) {
      changeLanguage(lang_or_token ?? 'en', true)
    }
  }, [lang_or_token])

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng')
    if (storedLanguage) {
      changeLanguage(storedLanguage, false)
    }
  })

  return (
    <div className="relative">
      <select
        className="appearance-none rounded-lg border-none bg-transparent px-3 py-2 font-medium text-black hover:text-black"
        value={selectedLanguage.code}
        onChange={handleChange}
      >
        {LANGUAGES.map((language) => (
          <option key={language.code} value={language.code}>
            {language.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg
          className="size-4 fill-current text-black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 12l-5-5 1.5-1.5L10 9.5 13.5 6 15 7.5z" />
        </svg>
      </div>
    </div>
  )
}

export default LanguageDropdown
