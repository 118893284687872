import React, { useContext } from 'react'
import axios from 'axios'

interface AuthState {
  isInitialised: boolean
  error?: string
}

interface AuthContextValue extends AuthState {
  authorize: (token: string | null) => Promise<void>
  setInvalid: () => void
  error?: string
  isAuthenticated: boolean
  userPack: 'boss' | 'premium' | 'free'
  loading: boolean
}

const initialAuthState: AuthState = {
  isInitialised: false,
}

export const AuthContext = React.createContext<AuthContextValue>(
  initialAuthState as AuthContextValue
)
export const useAuth = (): Readonly<AuthContextValue> => useContext(AuthContext)

export const AuthProvider = ({ children, match }: any) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [auth, setAuth] = React.useState<boolean>(false)
  const [userPack, setUserPack] = React.useState<'boss' | 'premium' | 'free'>(
    'free'
  )

  // check if there is a token present in the url, or localstorage
  const check_token = async (
    url_token: string | null
  ): Promise<string | null> => {
    const token_stored = localStorage.getItem('token')

    //if token does not contain -
    if (url_token && !url_token.includes('-') && url_token.length === 5) {
      localStorage.setItem('token', url_token)
      return Promise.resolve(url_token)
    }
    //if token is stored
    else if (token_stored) {
      return Promise.resolve(token_stored)
    }

    return Promise.reject(null)
  }

  const authorize = async (url_token: string | null) => {
    if (auth) {
      setLoading(false)
      return Promise.resolve()
    }
    const fetch_string = '/token/check/token'

    try {
      const token = await check_token(url_token)
      const response = await axios.post(fetch_string, { token })

      if (!response.data.error) {
        setAuth(true)
        if (response.data.userPack !== null) {
          setUserPack(response.data.userPack)
        } else {
          setUserPack('free')
        }

        setLoading(false)
        return Promise.resolve()
      } else {
        setInvalid()
      }
    } catch (e) {
      if (e) {
        console.log('err', e)
        setInvalid()
      }
    } finally {
      setLoading(false)
    }
    return Promise.reject()
  }

  const setInvalid = () => {
    setAuth(false)
    setLoading(false)
    setUserPack('free')
    localStorage.removeItem('token')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: auth,
        userPack: userPack,
        loading: loading,
        ...initialAuthState,
        authorize,
        setInvalid,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
