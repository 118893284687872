import { Switch } from '@headlessui/react'

interface MyToggleProps {
  enabled: boolean
  onChange: (enabled: boolean) => void
}

function MyToggle({ enabled, onChange }: MyToggleProps) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={enabled}
          onChange={onChange}
          className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${enabled ? 'translate-x-6' : 'translate-x-1'} - block size-4 rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  )
}

export default MyToggle
