import {
  createBrowserRouter,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom'
import Spinner from './1_pages/spinner/application'
import React, { useEffect, useRef, lazy, Suspense } from 'react'
import { NavBar } from './2_templates/NavBar'
import { translations } from './resources/locales'
import { useAuth } from './2_templates/AuthProvider'
import i18n from './resources/locales/i18n'
import LoadingScreen from './2_templates/LoadingScreen'
import mixpanel from 'mixpanel-browser'

const Pricing = lazy(() => import('./1_pages/pricing/pricing'))
const BossPackage = lazy(() => import('./2_templates/BossPackage'))
const PaymentConfirmation = lazy(() => import('./1_pages/pricing/confirmation'))
const Terms = lazy(() => import('./1_pages/terms/terms'))
const Privacy = lazy(() => import('./1_pages/terms/privacy'))
const ResetToken = lazy(() => import('./1_pages/spinner/resetToken'))

const Skeleton = ({ children }: any) => {
  const { authorize, isAuthenticated, loading } = useAuth()
  const { lang_or_token } = useParams()
  const navigate = useNavigate()
  const dataFetchedRef = useRef(false)

  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true

    console.log('isAuthenticated:', isAuthenticated)
    if (!isAuthenticated && loading) {
      authorize(lang_or_token ?? null).then(() => navigate('/'))
    }
  }, [])

  useEffect(() => {
    const isOldLanguageScheme = lang_or_token?.match(
      new RegExp('[a-z]{2}-[A-Z]{2}')
    )
    if (translations.includes(lang_or_token ?? '')) {
      i18n.changeLanguage(lang_or_token)
    } else if (isOldLanguageScheme) {
      navigate(`/${i18n.resolvedLanguage}`)
    }
  }, [lang_or_token])

  return (
    <Suspense fallback={<LoadingScreen />}>
      {!(loading || isAuthenticated) && <NavBar />}
      {!loading && children}
    </Suspense>
  )
}

const SkeletonEmpty = ({ children }: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <NavBar />
      {children}
    </Suspense>
  )
}

const SkeletonQr = ({ children }: any) => {
  useEffect(() => {
    mixpanel.track('qr-code-scanned')
  }, [])

  return (
    <Suspense fallback={<LoadingScreen />}>
      <NavBar />
      {children}
    </Suspense>
  )
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to={`${i18n.resolvedLanguage}`} replace />,
  },
  {
    path: '/qr',
    element: (
      <SkeletonQr>
        <Spinner />
      </SkeletonQr>
    ),
  },
  {
    path: '/:lang_or_token',
    element: (
      <Skeleton>
        <Spinner />
      </Skeleton>
    ),
  },
  {
    path: '/pricing',
    element: (
      <SkeletonEmpty>
        <Pricing />
      </SkeletonEmpty>
    ),
  },
  {
    path: '/pricing/boss',
    element: (
      <Skeleton>
        <BossPackage />
      </Skeleton>
    ),
  },
  {
    path: 'cancel',
    element: (
      <Skeleton>
        <h1>Cancel</h1>
      </Skeleton>
    ),
  },
  {
    path: 'success',
    element: (
      <>
        <NavBar />
        <PaymentConfirmation />
      </>
    ),
  },
  {
    path: 'terms-and-conditions',
    element: (
      <SkeletonEmpty>
        <Terms />
      </SkeletonEmpty>
    ),
  },
  {
    path: 'privacy-statement',
    element: (
      <SkeletonEmpty>
        <Privacy />
      </SkeletonEmpty>
    ),
  },
  {
    path: 'reset-token',
    element: (
      <SkeletonEmpty>
        <ResetToken />
      </SkeletonEmpty>
    ),
  },
  {
    path: '*',
    element: <h1>other</h1>,
  },
])
