import ar from './ar.json'
import bn from './bn.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import hi from './hi.json'
import id from './id.json'
import ja from './ja.json'
import ko from './ko.json'
import nl from './nl.json'
import pt from './pt.json'
import ru from './ru.json'
import zh from './zh.json'
import { Languages } from './Languages'

export const files = {
  ar,
  bn,
  de,
  en,
  es,
  fr,
  hi,
  id,
  ja,
  ko,
  nl,
  pt,
  ru,
  zh,
}

export const translations = [
  'ar',
  'bn',
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'id',
  'ja',
  'ko',
  'nl',
  'pt',
  'ru',
  'zh',
]
