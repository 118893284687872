import { CSSProperties } from 'react'

export const styles: { [key: string]: CSSProperties | any } = {
  modal: {
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  modalFont: {
    color: 'blue',
    fontSize: 40,
    marginLeft: 20,
  },
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    paddingBottom: 20,
    justifyContent: 'center',
    display: 'flex',
  },
  spinButton: {
    margin: 2,
    width: 300,
    backgroundColor: '#fee440',
    color: 'black',
  },
  cookies: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    height: 100,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 0,
    spacing: 9,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '32px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeButtonStyle: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  titleStyle: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    lineHeight: '1.5',
    marginTop: '8px',
  },
  subtitleStyle: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: '400',
    color: '#666',
    marginTop: '4px',
    marginBottom: '24px',
    lineHeight: '1.5',
  },
  formStyle: {
    width: '100%',
    marginBottom: '16px',
  },
  inputStyle: {
    marginBottom: '16px',
  },
  submitButton: {
    backgroundColor: 'black',
    color: 'white',
    padding: '12px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#333',
    },
    width: '100%',
  },
  disclaimerStyle: {
    marginTop: '16px',
    fontSize: '0.75rem',
    color: '#666',
    textAlign: 'center',
  },
}
