import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import mixpanel from 'mixpanel-browser'

import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { ColorState, GithubPicker } from 'react-color'
import { Wheel } from 'react-custom-roulette'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Confetti from 'react-confetti'
import { useAuth } from '../../2_templates/AuthProvider'
import MyToggle from '../../4_molecules/CustomSwitch'
import Ruimte from '../../4_molecules/Space'
import {
  OPTION_COLORS,
  Person,
  calculateAndFillSpinProbabilities,
  contrastingColor,
  empty_spin_data,
  nextStyle,
  people_to_wheel_data,
  pickNextPerson,
  pickNextPersonProb,
  pickOrderedList,
  sample_data,
  submitEmail,
} from './controller'
import { styles } from './styles'

function App() {
  const { isAuthenticated, userPack } = useAuth()
  const { t } = useTranslation()
  const [autoFocus, setAutoFocus] = useState(true)
  const [mustSpin, setMustSpin] = useState(false)

  const [people, setPeople] = useState<Person[]>(sample_data)

  const [refresh, setRefresh] = useState<boolean>(false)
  const [everyoneHasBeenPicked, setEveryoneHasBeenPicked] =
    useState<boolean>(false)
  const [pickedIndex, setPickedIndex] = useState<number>(-1)
  const [picked, setPicked] = useState<string[]>([])
  //Stores whether it is the first time spinning the wheel with a new ordered list
  const [firstSpinOrdered, setFirstSpinOrdered] = useState<boolean>(true)
  //only show the instructions on the rigged domain
  const [hideText, setHideText] = useState<boolean>(true)

  //textArea
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [orderedListTextAreaValue, setOrderedListTextAreaValue] =
    useState<string>('')
  const [orderedList, setOrderedList] = useState<Person[]>(sample_data)
  const [hideTextArea, setHideTextArea] = useState<boolean>(true)

  //Winner modal things
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  //Email modal things
  const [emailOpen, setEmailOpen] = useState<boolean>(false)
  const handleEmailOpen = () => setEmailOpen(true)
  const handleEmailClose = () => setEmailOpen(false)
  const [firstName, setFirstName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  //advanced options
  const [keepNamesAfterSpin, setKeepNames] = useState<boolean>(false)
  const [colorsEnabled, setColorsEnabled] = useState<boolean>(false)
  const [probabilityEnabled, setProbabilityOn] = useState<boolean>(false)
  const [listCompletelyOrdered, setListOrdered] = useState<boolean>(false)
  const [advancedOpen, setAdvancedOpen] = useState<boolean>(false)

  useEffect(() => {
    setAutoFocus(false)
  }, [])

  //notify user after not opening the site for 3 days
  useEffect(() => {
    if (isAuthenticated && userPack !== 'free') {
      return
    }

    const savedDateString = localStorage?.getItem('n')
    const savedDate = savedDateString ? parseInt(savedDateString) : 0
    if (isNaN(savedDate)) {
      console.error('Invalid date from localStorage')
      return
    }
    const currentDate = new Date().getTime()
    const THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000

    if (!savedDate || currentDate - savedDate > THREE_DAYS_IN_MILLISECONDS) {
      localStorage.setItem('n', currentDate.toString())
      toast.success(t<string>('spinner.text.checkout_premium'))
    }
  }, [])

  /**
   * Resets the wheel after all people have been picked.
   */
  const resetAfterAllPicked = () => {
    //reset people list
    for (const p of people) {
      p.picked = false
      p.style = nextStyle()
    }

    //this way, the orderedList can be used a second time without disappearing once the page is reloaded
    if (listCompletelyOrdered) {
      for (const o of orderedList) {
        o.picked = false
        o.style = nextStyle()
      }
      setFirstSpinOrdered(true)
    }

    setPicked([])
    setEveryoneHasBeenPicked(false)
  }

  /**
   * Toggles the probability feature for the wheel
   * @param checked - The current state of the feature
   */
  const checkBoxProbability = (checked: boolean) => {
    if (isAuthenticated && userPack !== 'free') {
      mixpanel.track(`feature-has-access`, {
        name: 'Probability',
      })
      if (listCompletelyOrdered) {
        toast.error(t('spinner.error.probability_ordered_both'))
      } else {
        setProbabilityOn(checked)
      }
    } else {
      mixpanel.track(`feature-no-access`, {
        name: 'Probability',
      })
      toast.error(t<string>('spinner.text.premium_feature'))
    }
  }

  /**
   * Toggles the color feature for the wheel
   * @param checked - The current state of the feature
   */
  const checkBoxColours = (checked: boolean) => {
    if (isAuthenticated && userPack === 'boss') {
      mixpanel.track(`feature-has-access`, {
        name: 'Color',
      })
      setColorsEnabled(checked)
    } else {
      mixpanel.track(`feature-no-access`, {
        name: 'Color',
      })
      toast.error(t<string>('spinner.text.boss_feature'))
    }
  }

  /**
   * Toggles the keep names feature for the wheel
   * @param checked - The current state of the feature
   */
  const checkBoxKeep = (checked: boolean) => {
    setKeepNames(checked)
  }

  /**
   * Toggles the ordered list feature for the wheel
   * @param checked - The current state of the feature
   */
  const checkBoxListOrder = (checked: boolean) => {
    if (userPack === 'boss') {
      mixpanel.track(`feature-has-access`, {
        name: 'OrderedList',
      })
      //setListOrder
      if (probabilityEnabled) {
        toast.error(t('spinner.error.probability_ordered_both'))
      } else {
        setListOrdered(checked)
        if (!checked) {
          setOrderedList(sample_data)
        }
      }
    } else {
      mixpanel.track(`feature-no-access`, {
        name: 'OrderedList',
      })
      toast.error(t<string>('spinner.text.boss_feature'))
    }
  }

  /**
   * Update the name of a user of the corresponding input field
   * @param p - The edited name
   * @param i - The index of the user
   */
  const updateInputField = (p: string, i: number) => {
    const str = p
    const peopleCopy = people
    const person = people[i]

    if (!person) {
      console.log('updateInputField', 'person is undefined')
      return toast.error(t('spinner.error.unknown'))
    }

    person.option = str.replace('.', '').replace(',', '')

    //The handling of the rigging of the wheel
    if (
      person.place !== 'last' &&
      (p.includes('.') || p.toLowerCase().includes('lar'))
    ) {
      person.place = 'last'
    }
    if (person.place !== 'first' && p.includes(',')) {
      person.place = 'first'
    }
    peopleCopy[i] = person

    setPeople(peopleCopy)
    setRefresh(!refresh)
  }

  /**
   * Change the probability of a user.
   * @param e - The event of the input field from which to extract the new probability
   * @param i - The index of the user
   */
  const changeProbability = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    if (!e.target.value) {
      console.log('changeProbability', 'e.target.value is empty')
      return toast.error(t('spinner.error.empty'))
    }
    const p = Number(e.target.value)
    const peopleNew = people
    if (p > 100) return

    const person = peopleNew[i]
    if (!person) {
      console.log('no person', 'changeProbability 0')
      return toast.error(t('spinner.error.unknown'))
    }
    person.p = p

    updatePerson(i, person)
  }

  /**
   * Change the color of a user on the wheel.
   * @param color - The new color
   * @param index - The index of the user
   */
  const changeColor = (color: ColorState, index: number) => {
    const person = people[index]

    person.style.background_color = color.hex
    person.style.text_color = contrastingColor(color.hex)
    updatePerson(index, person)
  }

  /**
   * Change the amount of names on the wheel.
   * @param e - The event of the input field from which to extract the new amount
   */
  const changeInputAmount = (e: any) => {
    if (!e.target.value) {
      console.log('changeInputAmount', 'e.target.value is empty')
      return
    }
    if (isNaN(e.target.value) || e.target.value < 0)
      return toast.error(t('spinner.error.nan'))

    //'==' because we already checked for null, and value is a string
    if (e.target.value == 0) {
      changeListLength(0)
      setRefresh(!refresh)
      return
    }

    //Cap amount of names to 11 if the user package is free
    if (e.target.value > 11 && userPack === 'free') {
      mixpanel.track(`too-many-names`, {
        userPack: 'Free',
        amount: e.target.value,
      })
      console.log('Userpack:', userPack)
      return toast.error(t('spinner.error.too_many_free'))

      //Cap amount of names to 30 if the user package is premium
    } else if (e.target.value > 30 && userPack === 'premium') {
      mixpanel.track(`too-many-names`, {
        userPack: 'Premium',
        amount: e.target.value,
      })
      return toast.error(t('spinner.error.too_many_premium'))
    }

    //Load inputs once the amount is valid
    mixpanel.track('valid-amount-of-names', {
      userPack: userPack,
      amount: e.target.value,
    })
    changeListLength(e.target.value)
  }

  /**
   * Add an extra name to the wheel.
   * @param e - The event of the button click
   */
  const addSingleName = (e: any) => {
    e.preventDefault()
    if (userPack === 'free' && people.length === 11) {
      mixpanel.track(`too-many-names`, {
        userPack: 'Free',
        amount: 12,
      })
      return toast.error(t('spinner.error.too_many_free'))
    } else if (userPack === 'premium' && people.length === 30) {
      mixpanel.track(`too-many-names`, {
        userPack: 'Premium',
        amount: 31,
      })
      return toast.error(t('spinner.error.too_many_premium'))
    }
    mixpanel.track('valid-amount-of-names', {
      userPack: userPack,
      amount: e.target.value,
    })
    people?.push(empty_spin_data())
    setPeople(people)
    setRefresh(!refresh)
  }

  /**
   * Load a new list of names into the wheel.
   */
  const changeListLength = (amount: number) => {
    if (amount === 0) {
      for (let i = 1; i <= people.length; i++) {
        people.pop()
      }
      const person = people[0]
      person.option = ''
      updatePerson(0, person)
      return
    }
    const diff = amount - people?.length
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        people?.push(empty_spin_data())
      }
      setEveryoneHasBeenPicked(false)
    } else if (diff < 0) {
      for (let i = 0; i < -diff; i++) {
        people?.pop()
      }
    }
    setPeople(people)
    setRefresh(!refresh)
  }

  /**
   * Delete a name from the wheel.
   * @param i - The index of the name to delete
   */
  const deleteName = (i: number) => {
    if (!people) {
      console.log('deleteName', 'people is undefined')
      return toast.error(t('spinner.error.unknown'))
    }
    if (people?.length === 1) {
      console.log('deleteName', 'people.length is 1')
      changeListLength(0)
      setRefresh(!refresh)
      return
    }
    people?.splice(i, 1)
    changeListLength(people?.length ?? 0)
    setRefresh(!refresh)
  }

  /**
   * Checks if all inputs on the wheel are valid.
   * @param peopleNew
   */
  const checkInputs = async (
    peopleNew: Person[] | undefined
  ): Promise<void> => {
    setHideText(true)

    if (!peopleNew || !Array.isArray(peopleNew)) {
      throw new Error(t<string>('spinner.error.empty'))
    }

    const amountPicked = peopleNew?.filter((person) => person.picked)?.length

    if (mustSpin) {
      throw new Error(t<string>('spinner.error.spinning'))
    }

    if (peopleNew?.some((x) => !x.option)) {
      throw new Error(t<string>('spinner.error.empty'))
    }

    if (!keepNamesAfterSpin && amountPicked >= peopleNew?.length) {
      throw new Error(t<string>('spinner.error.max'))
    }
  }

  /**
   * Spin the wheel and pick a person. - Unordered, with probability or ordered.
   * @param e - The event of the button click
   */
  const spinWheel = async (e: any) => {
    e.preventDefault()
    setMustSpin(false)
    try {
      let index_to_pick: number = -1
      await checkInputs(people)

      if (!listCompletelyOrdered) {
        if (!people) {
          console.log('no people', 'spinWheel 0.a')
          return toast.error(t('spinner.error.unknown'))
        }

        index_to_pick = await choosePersonNormalOrProbability()
      } else {
        if (!people || !orderedList || people?.length !== orderedList?.length) {
          console.log('list size error', 'spinWheel 1', people, orderedList)
          return toast.error(t('spinner.error.list_size'))
        }

        index_to_pick = await choosePersonOrderedList()

        if (index_to_pick === -1) {
          return toast.error(t('spinner.error.unknown'))
        }
      }
      //amount of people that have been picked equals the amount of people
      if (picked?.length >= people?.length - 1) {
        setEveryoneHasBeenPicked(true)
      }

      window?.scrollTo({ top: 0, behavior: 'smooth' })

      setPickedIndex(index_to_pick)
      console.log(pickedIndex, 'index_to_pick')

      setMustSpin(true)
    } catch (e) {
      console.log(e, 'spinWheel - unknown')
      if (typeof e === 'string') return toast.error(e)
      else if (e instanceof Error) return toast.error(e.message)
      else return toast.error(t<string>('spinner.error.unknown'))
    }
  }

  /**
   * Chooses a person from the wheel with either the basic way or using probability
   */
  const choosePersonNormalOrProbability = async () => {
    let peopleNew = people
    if (probabilityEnabled) {
      peopleNew = await calculateAndFillSpinProbabilities(people)
    }

    let person: Person

    if (probabilityEnabled) {
      person = await pickNextPersonProb(peopleNew, keepNamesAfterSpin)
    } else {
      person = await pickNextPerson(keepNamesAfterSpin, peopleNew)
    }
    if (!person) {
      console.log('no person', 'spinWheel 0.1')
      throw new Error(t<string>('spinner.error.unknown'))
    }
    console.log('person picked - ', person)

    const index_to_pick = people?.findIndex(
      (element) => element?.option === person?.option
    )

    //remove the tags when names should be reused.
    if (keepNamesAfterSpin) {
      person.place = 'middle'
    }

    setPeople(peopleNew)

    return index_to_pick
  }

  /**
   * Chooses a person from the ordered list.
   */
  const choosePersonOrderedList = async () => {
    let peopleNew // peopleNew is the list you update with the picked person

    //Triggered the first time the wheel is spun when the ordered list is enabled
    if (firstSpinOrdered) {
      //Shuffling list so the user won't notice the order
      const shuffledList = [...orderedList] // OrderedList is the list that is set when enabling the ordered list
      shuffledList?.sort(() => Math.random() - 0.5)

      //Setting correct colors so the shuffling is not noticeable
      for (let i = 0; i < people?.length; i++) {
        shuffledList[i].style.background_color =
          people[i].style.background_color
      }

      //Storing the new list correctly, so it is used for spinning
      peopleNew = await setOrderedFirstTime(shuffledList) // async because otherwise the wheel wasn't loaded correctly

      setRefresh(!refresh)
      setFirstSpinOrdered(false)
    } else {
      //If it is not the first time spinning, continue with the list as is.
      peopleNew = people
    }

    await checkInputs(peopleNew)

    const person = pickOrderedList(orderedList)

    if (!person) {
      console.log('no person', 'spinWheel 1')
      throw new Error(t<string>('spinner.error.unknown'))
    }

    if (!peopleNew) {
      console.log('no peopleNew', 'spinWheel 1')
      throw new Error(t<string>('spinner.error.unknown'))
    }
    let index_to_pick = -1

    //Find the index of the picked person from the ordered list in the shuffled list
    for (let i = 0; i < peopleNew?.length; i++) {
      if (
        peopleNew[i]?.option?.toLowerCase() === person.option?.toLowerCase()
      ) {
        console.log('Picking!!', person.option, i)
        index_to_pick = i
        break // Exit the loop once the target is found
      }
    }

    return index_to_pick
  }

  /**
   * Load the names from the big input text area into the wheel.
   */
  const loadTextArea = () => {
    if (!textareaValue || textareaValue.trim() === '') {
      console.log('load', 'textareaValue is empty')
      return toast.error(t('spinner.error.empty'))
    }
    const lines = textareaValue?.split('\n')

    if (!lines) {
      console.log('load', 'lines is undefined')
      return toast.error(t('spinner.error.unknown'))
    }

    const newPeople = lines
      .map((line) => {
        if (!line) {
          console.log('load', 'line is undefined')
          return null
        }
        if (line?.trim() === '') return null // skip blank lines

        let place = 'middle' as 'first' | 'middle' | 'last'

        if (line.includes('.') || line.toLowerCase().includes('lar')) {
          place = 'last'
        }
        if (line.includes(',')) {
          place = 'first'
        }

        const str = line.replace('.', '').replace(',', '')

        return {
          option: str,
          place: place,
          picked: false,
          style: nextStyle(),
          p: 0,
        }
      })
      .filter(Boolean) as Person[] // remove null values and cast to Person[]

    setPeople(newPeople ?? [])
    setRefresh(!refresh)
    setHideTextArea(true)
  }

  /**
   * Set the people list for the first time when using an orderedlist.
   * @param shuffledList
   */
  const setOrderedFirstTime = async (shuffledList: Person[]) => {
    //Stores the lists correctly
    setPeople(shuffledList)
    return shuffledList
  }

  /**
   * Stores the list with the people using the ordered list.
   */
  const setHiddenOrder = () => {
    if (!orderedListTextAreaValue) {
      console.log('setHiddenOrder', 'orderedListTextAreaValue is empty')
      return toast.error(t('spinner.error.empty'))
    }
    const lines = orderedListTextAreaValue.split('\n')
    if (!lines) {
      console.log('setHiddenOrder', 'lines is undefined')
      return toast.error(t('spinner.error.unknown'))
    }

    const newPeople = lines
      .map((line) => {
        if (!line) {
          console.log('setHiddenOrder', 'line is undefined')
          return null
        }
        if (line.trim() === '') return null // skip blank lines
        return {
          option: line.replace('.', '').replace(',', ''),
          place: 'middle',
          picked: false,
          style: nextStyle(),
          p: 0,
        }
      })
      .filter(Boolean) as Person[]

    console.log('New people:', newPeople)
    setOrderedList(newPeople)
    setOrderedListTextAreaValue('')
    setFirstSpinOrdered(true)
    toast.success(t<string>('spinner.text.loaded_order'))
    setAdvancedOpen(!advancedOpen)
  }

  /**
   * Update the person at the specified index with the new person.
   * @param i - The index of the person to update
   * @param person - The new person
   */
  const updatePerson = (i: number, person: Person) => {
    if (!people) {
      console.log('updatePerson', 'people is undefined')
      return toast.error(t('spinner.error.unknown'))
    }
    const newPeople = people // create a new copy of the people array
    newPeople[i] = person // replace the person at the specified index with the new person
    setPeople(newPeople) // update the state with the new people array
    setRefresh(!refresh)
  }

  /**
   * Remove the picked person from the list of people and add to the picked list.
   */
  const afterSpinning = () => {
    try {
      if (typeof pickedIndex === 'undefined' || !people) {
        console.log('pickedIndex', 'afterSpinning 0', pickedIndex)
        return toast.error(t('spinner.error.unknown'))
      }

      if (pickedIndex < 0) {
        console.log('pickedIndex', 'afterSpinning 0', pickedIndex)
        return toast.error(t('spinner.error.unknown'))
      }

      const person = people[pickedIndex]

      if (!person) {
        console.log('no person', 'afterSpinning 0')
        return toast.error(t('spinner.error.unknown'))
      }

      const pickedNew = picked
      pickedNew?.push(person.option)

      if (!keepNamesAfterSpin) {
        person.style.background_color = 'white'
        person.style.text_color = 'black'
      }

      handleOpen()
      setPicked(pickedNew)
      setMustSpin(false)
      setRefresh(!refresh)
    } catch (e) {
      console.log(e, 'afterSpinning - unknown')
      if (typeof e === 'string') return toast.error(e)
      else if (e instanceof Error) return toast.error(e.message)
      else return toast.error(t<string>('spinner.error.unknown'))
    }
  }

  const pressHide = () => {
    setHideText(!hideText)
  }

  /**
   * Clear the wheel and reset to the original state
   */
  const clearWheel = () => {
    const newSampleData: Person[] = [
      empty_spin_data(),
      empty_spin_data(),
      empty_spin_data(),
    ]
    setPicked([])
    setPeople(newSampleData)
    setRefresh(!refresh)
  }

  const handleEmailSubmit = (firstname: string, email: string) => {
    if (firstname !== '' && email !== '') {
      handleEmailClose()
      submitEmail(firstname, email)
    }
    return
  }

  return (
    <>
      <Toaster />
      <Container fluid style={styles.container}>
        <Container>
          {
            //Container that contains the app banner
            (!isAuthenticated || userPack === 'free') && (
              <div className={'mb-3 flex items-center justify-center'}>
                <div className="w-full lg:w-1/3">
                  <button onClick={handleEmailOpen}>
                    <img
                      src={`${process.env.PUBLIC_URL}/background-banner.webp`}
                      alt="banner"
                    />
                  </button>
                </div>
              </div>
            )

            //Contains the instructions for the wheel, hidden when a package is purchased
          }
          <div className="flex items-center justify-center">
            <motion.div
              className={`${isAuthenticated && userPack !== 'free' ? 'bg-gray-100' : 'bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500'} mx-auto mb-3 flex w-full flex-col items-center justify-center rounded-lg p-4 lg:w-1/3`}
            >
              <h1
                className={`${isAuthenticated && userPack !== 'free' ? 'text-black' : 'text-white'} mb-1 mt-0 text-center text-4xl font-bold leading-tight md:text-5xl`}
              >
                {t('spinner.text.title')}
              </h1>
              <h2 className="mb-4 text-center text-xl font-medium leading-normal text-gray-700 md:text-2xl">
                {t('spinner.text.subtitle')}
              </h2>
              {hideText && (!isAuthenticated || userPack === 'free') && (
                <button
                  onClick={pressHide}
                  className={`${isAuthenticated && userPack !== 'free' ? 'bg-black' : 'bg-white'} focus:shadow-outline w-80 rounded-lg px-6 py-1 font-medium transition-all duration-200 ease-in-out hover:bg-purple-900 focus:outline-none md:w-96`}
                >
                  Instructions
                </button>
              )}
              {!hideText && (!isAuthenticated || userPack === 'free') && (
                <button
                  onClick={pressHide}
                  className="focus:shadow-outline w-96 rounded-lg bg-white px-6 py-1 font-medium text-black transition-all duration-200 ease-in-out hover:bg-purple-900 focus:outline-none"
                >
                  Hide Instructions
                </button>
              )}
            </motion.div>
          </div>

          {
            //Displayed when the instructions are opened
            !hideText && (!isAuthenticated || userPack === 'free') && (
              <>
                <div className="mx-auto w-full rounded-lg bg-white p-6 lg:w-2/3">
                  <h3 className="mb-4 text-lg font-bold">
                    {t('spinner.text.instructions_text')}
                  </h3>
                  <p className="mb-4 text-gray-600">
                    {t('spinner.text.instructions_detailed')}
                  </p>
                  <div className="mb-4 rounded-lg bg-green-100 p-4">
                    <p className="my-2 text-green-600">
                      {t('spinner.text.instructions_comma')}
                    </p>
                    <p className="text-green-600">
                      {t('spinner.text.instructions_dot')}
                    </p>
                  </div>
                </div>
              </>
            )
          }

          {
            //The wheel itself
          }
          <Row className={'flex items-center justify-center'}>
            <a onClick={spinWheel}>
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={pickedIndex}
                data={people_to_wheel_data(people)}
                onStopSpinning={afterSpinning}
                backgroundColors={['#3e3e3e', '#df3428']}
                textColors={['#ffffff']}
              />
            </a>
            <Ruimte y={1} />
          </Row>

          {
            //Contains the spin/reset button
          }
          <div className="flex items-center justify-center">
            {everyoneHasBeenPicked ? (
              //reload the page
              <button
                onClick={resetAfterAllPicked}
                disabled={mustSpin}
                className="mt-5 inline-block w-96 rounded-lg bg-gray-500 px-6 py-2 text-lg font-bold text-white hover:bg-gray-600 md:w-1/3"
              >
                <b>{t('spinner.text.reset')}</b>
              </button>
            ) : (
              <button
                onClick={spinWheel}
                disabled={mustSpin}
                className="mb-2 inline-block w-96 rounded-lg bg-blue-500 py-2 text-lg font-bold text-white hover:bg-blue-600 md:w-1/3"
              >
                <b>{t('spinner.text.spin')}</b>
              </button>
            )}
          </div>

          {
            //Displays the list of people that have been picked
            picked?.length > 0 && (
              <div className="flex items-center justify-center">
                <div className="my-2 w-96 overflow-hidden rounded bg-gray-100 md:w-2/3">
                  <div className="px-6 py-4">
                    <div className="mb-2 text-xl font-bold">
                      List of people picked
                    </div>
                    {picked.map((p, i) => {
                      return (
                        <div key={i}>
                          <h6 className="text-blue-600">
                            {Number(i + 1)}. {p}
                          </h6>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          }

          {
            //The popup that is displayed when the winner is selected
          }
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="relative">
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <Confetti
                  recycle={true}
                  numberOfPieces={1000}
                  colors={[
                    '#FFC300',
                    '#DAF7A6',
                    '#FF5733',
                    '#C70039',
                    '#900C3F',
                  ]}
                  width={window.innerWidth}
                  height={window.innerHeight}
                  gravity={1}
                />
              </div>
              <div className="fixed left-1/2 top-1/2 z-[9999] w-[90%] max-w-[400px] -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-5 text-center shadow-lg">
                <button
                  onClick={handleClose}
                  className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <h2
                  id="modal-modal-title"
                  className="mb-6 text-4xl font-extrabold text-indigo-600"
                >
                  {t('spinner.text.who_is_it')}
                </h2>
                <p
                  id="modal-modal-description"
                  className="mt-4 text-2xl font-semibold text-gray-700"
                >
                  {t('spinner.text.it_is')}
                </p>
                <h3 className="mt-6 animate-bounce break-words text-5xl font-black text-indigo-600">
                  {picked?.[picked?.length - 1]}
                </h3>
                <div className="mt-8 flex justify-center">
                  <button
                    className="rounded-full bg-indigo-500 px-6 py-3 font-bold text-white transition duration-300 ease-in-out hover:scale-105 hover:bg-indigo-600"
                    onClick={handleClose}
                  >
                    Ok!
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {
            //The modal for e-mail subscription
          }
          <Modal
            open={emailOpen}
            onClose={handleEmailClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles.modalStyle}>
              <IconButton
                aria-label="close"
                onClick={handleEmailClose}
                sx={styles.closeButtonStyle}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 96 960 960"
                  width="24"
                  fill="currentColor"
                >
                  <path d="M256 928l224-224 224 224 56-56-224-224 224-224-56-56-224 224-224-224-56 56 224 224-224 224 56 56z" />
                </svg>
              </IconButton>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={styles.titleStyle}
              >
                Join the waitlist for Early Access
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={styles.subtitleStyle}
              >
                First come, first serve.
              </Typography>
              <Box sx={styles.formStyle}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                  sx={styles.inputStyle}
                />
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  sx={styles.inputStyle}
                />
                <button
                  className="mt-4 w-full bg-black py-3 font-bold text-white hover:bg-gray-800"
                  onClick={() => handleEmailSubmit(firstName, email)}
                >
                  Sign up
                </button>
              </Box>
              <Typography variant="body2" sx={styles.disclaimerStyle}>
                When filling out this form, you consent to receive one email
                when the app is released. We wont use it to contact you later.
              </Typography>
            </Box>
          </Modal>

          {
            //Input field to select the amount of names on the wheel
            (userPack !== 'boss' || hideTextArea) && (
              <div className="flex items-center justify-center">
                <div className="mx-auto w-96 overflow-hidden rounded-lg bg-gray-100 p-4 md:w-2/3">
                  <h2 className="mb-2 text-xl font-bold">
                    {t('spinner.text.amount_of_people')}
                  </h2>
                  <input
                    autoFocus={autoFocus}
                    placeholder={
                      t('spinner.text.amount_of_people') ?? 'Amount of people'
                    }
                    onChange={changeInputAmount}
                    type="number"
                    className="w-full rounded-lg border border-gray-400 bg-white px-4 py-2 text-lg font-medium focus:border-blue-500 focus:outline-none"
                  />
                </div>
              </div>
            )
          }

          {
            //Component for the names input and the advanced options.
          }
          <div className="flex items-center justify-center">
            <div className="mt-2 w-96 overflow-hidden rounded bg-gray-100 px-6 py-4 md:w-2/3">
              <div className="mb-2 text-xl font-bold">Options</div>

              {
                //Component for the single text area to copy/paste
                userPack === 'boss' && !hideTextArea && (
                  <div className="flex flex-col items-center justify-center">
                    <textarea
                      placeholder={t<string>('spinner.text.name_per_line')}
                      value={textareaValue}
                      onChange={(e) => {
                        if (!e.target.value) {
                          console.log('setTextareaValue 1', 'empty')
                          setTextareaValue('')
                          return
                        }
                        return setTextareaValue(e.target.value)
                      }}
                      className="m-1 h-[8em] rounded-md border border-gray-300 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 md:w-1/2"
                    />
                    <div className="flex flex-row">
                      <button
                        onClick={clearWheel}
                        className="m-1 rounded-md bg-indigo-500 p-2 text-white focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-700"
                      >
                        {t('spinner.text.clear')}
                      </button>
                      <button
                        onClick={loadTextArea}
                        className="m-1 rounded-md bg-indigo-500 p-2 text-white focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-700"
                      >
                        Load
                      </button>
                    </div>
                  </div>
                )
              }

              {
                //Component for the input with separate fields for each user
                (userPack !== 'boss' || hideTextArea) &&
                  people &&
                  people.map((person, i) => {
                    return (
                      <div className="mb-1 flex items-center" key={i}>
                        <input
                          placeholder={t<string>('spinner.text.enter_name')}
                          value={person.option}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (!e.target.value) {
                              console.log('updateInputField 1', 'empty')
                              updateInputField('', i)
                              return
                            }
                            updateInputField(e.target.value, i)
                          }}
                          className="m-1 w-full rounded-md border border-gray-300 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                        <button
                          type="button"
                          className="ml-2 rounded-lg bg-red-700 px-2 py-1 text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                          onClick={() => deleteName(i)}
                        >
                          X
                        </button>
                      </div>
                    )
                  })
              }

              {
                //The add name and clear wheel button component
                (userPack !== 'boss' || hideTextArea) && (
                  <>
                    <button
                      onClick={addSingleName}
                      className="mt-2 w-full rounded bg-teal-500 px-4 py-2 font-bold text-white hover:bg-teal-700"
                    >
                      {t('spinner.text.add')}
                    </button>
                    <button
                      onClick={clearWheel}
                      className="mt-2 w-full rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                    >
                      {t('spinner.text.clear_wheel')}
                    </button>
                  </>
                )
              }

              <Ruimte y={2} x={0} />

              {
                //The single text area toggle
                userPack === 'boss' && (
                  <div style={styles.flexRow}>
                    <h5>{t('spinner.text.copy_paste_field')}</h5>
                    <MyToggle
                      enabled={!hideTextArea}
                      onChange={() => setHideTextArea(!hideTextArea)}
                    />
                  </div>
                )
              }

              <Ruimte y={1} x={0} />

              {
                //The advanced options toggle
              }
              <div style={styles.flexRow}>
                <h5>{t<string>('spinner.text.advanced_options')}</h5>

                <MyToggle
                  enabled={advancedOpen}
                  onChange={() => setAdvancedOpen(!advancedOpen)}
                />
              </div>

              {
                //The component containing the advanced options
                advancedOpen && (
                  <>
                    <hr className="my-6 border-t-2 border-gray-400" />

                    {
                      //The component containing the keep names after spin toggle
                    }
                    <div style={styles.flexRow}>
                      <p>{t<string>('spinner.text.keep_in_list')}</p>
                      <MyToggle
                        enabled={keepNamesAfterSpin}
                        onChange={checkBoxKeep}
                      />
                    </div>

                    <br />

                    {
                      //The component containing the probability feature toggle
                    }
                    <div style={styles.flexRow}>
                      <p>{t<string>('spinner.text.use_probability')}</p>
                      <MyToggle
                        enabled={probabilityEnabled}
                        onChange={checkBoxProbability}
                      />
                    </div>

                    {
                      //The component containing the probability feature settings
                    }
                    <div className="flex flex-col items-center justify-center transition-all duration-500 ease-in-out">
                      {probabilityEnabled &&
                        people &&
                        people.map((person, i) => {
                          return (
                            <div
                              className="mt-2 flex flex-row overflow-hidden rounded bg-gray-100 px-6 md:w-2/3"
                              key={i}
                            >
                              <p className="m-1 w-full rounded-md border border-gray-300 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                {person.option}
                              </p>
                              <input
                                placeholder={t<string>(
                                  'spinner.text.probability_placeholder'
                                )}
                                value={person?.p}
                                type={'number'}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => changeProbability(e, i)}
                                style={{ marginBottom: 5 }}
                                className="m-1 w-full rounded-md border border-gray-300 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              />
                            </div>
                          )
                        })}
                    </div>

                    <br />

                    {
                      //The component containing the color feature toggle
                    }
                    <div style={styles.flexRow}>
                      <p>{t('spinner.text.select_colors')}</p>
                      <MyToggle
                        enabled={colorsEnabled}
                        onChange={checkBoxColours}
                      />
                    </div>

                    {
                      //The component containing the color feature settings
                    }
                    <div className="flex flex-col items-center justify-center transition-all duration-500 ease-in-out">
                      {colorsEnabled &&
                        people &&
                        people.map((person, i) => {
                          return (
                            <div
                              className="mt-2 flex flex-row overflow-hidden rounded bg-gray-100 px-6 md:w-2/3"
                              key={i}
                            >
                              <p
                                className="m-1 w-full rounded-md p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                style={{
                                  borderColor: person.style.background_color,
                                  borderWidth: 4,
                                  borderStyle: 'solid',
                                }}
                              >
                                {person.option ?? ''}
                              </p>

                              <GithubPicker
                                colors={OPTION_COLORS}
                                onChange={(color: ColorState) =>
                                  changeColor(color, i)
                                }
                              />
                            </div>
                          )
                        })}
                    </div>

                    <br />

                    {
                      //The component containing the completely ordered list feature toggle
                    }
                    <div style={styles.flexRow}>
                      <p>{t<string>('spinner.text.set_order')}</p>
                      <MyToggle
                        enabled={listCompletelyOrdered}
                        onChange={checkBoxListOrder}
                      />
                    </div>

                    {
                      //The component containing the completely ordered list feature settings
                      listCompletelyOrdered && (
                        <div className="flex h-full flex-col items-center justify-center transition-all duration-500 ease-in-out">
                          <br />
                          <p className="text-gray w-2/3 text-sm opacity-75">
                            {t<string>('spinner.text.order_explanation')}
                          </p>
                          <textarea
                            placeholder={t<string>(
                              'spinner.text.name_per_line'
                            )}
                            value={orderedListTextAreaValue ?? ''}
                            onChange={(e) => {
                              if (!e.target.value) {
                                console.log(
                                  'setOrderedListTextAreaValue',
                                  'empty'
                                )
                                setOrderedListTextAreaValue('')
                                return
                              }
                              setOrderedListTextAreaValue(e.target.value)
                            }}
                            className="m-1 w-2/3 rounded-md border border-gray-300 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          />
                          <button
                            onClick={setHiddenOrder}
                            className="m-1 rounded-md bg-indigo-500 p-2 text-white focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-700"
                          >
                            {t<string>('spinner.text.set')}
                          </button>
                        </div>
                      )
                    }

                    <br />
                  </>
                )
              }

              {
                //An extra spin wheel button
              }
              <button
                onClick={spinWheel}
                disabled={mustSpin}
                className="mt-2 w-full rounded bg-yellow-400 px-4 py-2 font-bold text-black hover:bg-yellow-500"
              >
                <b>{t('spinner.text.spin')}</b>
              </button>
            </div>
          </div>

          {
            //A footer for users without a package
            (!isAuthenticated || userPack === 'free') && (
              <>
                <div className="mt-6 flex items-center justify-center">
                  <div className="w-96 overflow-hidden rounded bg-gray-100">
                    <div className="px-6 py-4">
                      <div className="mb-2 text-xl font-bold">About</div>
                      <p>{t('spinner.text.own_domain_question')}</p>
                      <p className="mt-2 text-blue-500 hover:underline">
                        <Link to="/pricing">
                          {t('spinner.text.own_domain_answer')}
                        </Link>
                      </p>
                      <div className="mt-4 flex flex-col space-y-1">
                        <a
                          href="https://forms.gle/XWV2CywfhtLLB1RZ9"
                          target="_blank"
                          className="text-gray-700 hover:underline"
                        >
                          Leave feedback
                        </a>
                        <Link
                          to={'/terms-and-conditions'}
                          className="text-gray-700 hover:underline"
                        >
                          Terms
                        </Link>
                        <Link
                          to={'/privacy-statement'}
                          className="text-gray-700 hover:underline"
                        >
                          Privacy
                        </Link>
                        <a
                          href={'mailto:hello@riggedwheel.com'}
                          className="text-gray-700 hover:underline"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center py-8">
                  <Link to={`/pricing`}>
                    <motion.button
                      whileHover={{
                        scale: 1.05,
                        y: -2,
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
                      }}
                      whileTap={{ scale: 0.9 }}
                      className="inline-block rounded-lg px-6 py-3 text-lg font-bold text-white focus:outline-none"
                      style={{
                        background:
                          'linear-gradient(90deg, #F56565 0%, #E1306C 30%, #C13584 60%, #833AB4 90%)',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <b>Pricing</b>
                    </motion.button>
                  </Link>
                </div>
              </>
            )
          }
        </Container>
      </Container>
    </>
  )
}

export default App
