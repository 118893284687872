import React from 'react'
import './resources/styles/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactDOM from 'react-dom/client'
import mixpanel from 'mixpanel-browser'

import { RouterProvider } from 'react-router-dom'

import './resources/locales/i18n'
import { router } from './routes'
import { AuthProvider } from './2_templates/AuthProvider'
import axios from 'axios'
import { Header } from './3_organisms/Header'

mixpanel.init('eca4428c3eaed8a8c024437a8b0b3b88')

axios.defaults.baseURL = 'https://helloworld-wct5w5mmdq-uc.a.run.app/api'
//axios.defaults.baseURL = 'http://localhost:8080/api'
axios.defaults.withCredentials = true

const c = document.getElementById('root')
if (!c) throw new Error("The root wasn't found")
const root = ReactDOM.createRoot(c)
root.render(
  <AuthProvider>
    <Header>
      <RouterProvider router={router} />
    </Header>
  </AuthProvider>
)
