import React from 'react'

export default function Space({
  x,
  y,
  className,
}: {
  x?: number
  y?: number
  className?: string
}) {
  if (y && y > 0 && y < 100) {
    return (
      <div className={className} style={{ width: '100%', height: 20 * y }} />
    )
  } else if (x && x > 0 && x < 100) {
    return (
      <div className={className} style={{ height: '100%', width: 20 * x }} />
    )
  } else
    return <div className={className} style={{ width: '100%', height: 20 }} />
}
