import React from 'react'

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="size-32 animate-spin rounded-full border-y-2 border-gray-900" />
    </div>
  )
}

export default LoadingScreen
